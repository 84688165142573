.settings {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .settings_container {
    width: 500px;
    min-height: 400px;
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgb(223, 223, 223);
    padding: 15px 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .settings_title {
      text-align: center;
      font-size: 28px;
    }

    .change_measurement {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .measurement_title {
        font-size: 22px;
      }

      .select_option_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        padding: 4px;
        background-color: #EAECF0;
        border-radius: 8px;
        min-width: 240px;

        .select_btn {
          border: none;
          background-color: transparent;
          padding: 8px 30px;
          border-radius: 5px;
          min-width: 146px;
          text-transform: capitalize;

          &:focus {
            outline: none;
          }
        }

        .selected {
          background-color: #4191ff;
          color: white;
        }
      }
    }

    .save_btn {
      margin-top: 35px;
      border: none;
      background-color: #4191ff;
      color: white;
      padding: 8px 30px;
      border-radius: 8px;
      justify-self: flex-end;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #3487fb;
      }
    }

    @media (max-width: 768px) {
      max-width: 360px;

      .settings_title {
        font-size: 23px;
      }

      .measurement_title {
        font-size: 20px;
      }
    }
  }
}