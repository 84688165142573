.header-filters {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.blue_status {
  color: #000dff;
}

.deleted {
  color: red;
}

.edited {
  color: orange;
}

.inventory_pdf_excel {
  background: transparent !important;
  color: #3b3e66 !important;
  cursor: pointer !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  width: fit-content !important;

  &:hover {
    transform: translate(0) !important;
    color: #4191ff !important;
  }
}

.inventory_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .right_side {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;

    .iiko_buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .load_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        > svg {
          width: 21px;
          height: 21px;
          animation: anim 0.9s linear infinite;
        }
      }
    }

    .hide_button {
      display: none;
    }
  }
}

.success {
  color: #22bb33;
}

.issue {
  color: #bb2124;
}

.deleted_at {
  color: grey;
}
