.messengers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px;
  background: #f6f6f6;
  height: 100%;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 16px 0;
  }
  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .logo {
    width: 200px;
    height: 58px;

    @media (max-width: 1379px) {
      width: 130px;
      height: 38px;
    }

    @media (max-width: 676px) {
      width: 110px;
      height: 32px;
    }
  }
  .messenger-item {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: white;
    border: 2px solid #e9e9ea;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    text-decoration: none;
    @media (max-width: 768px) {
      padding: 10px;
      margin-bottom: 10px;
    }
    &:hover {
      color: unset;
    }
    span {
      display: flex;
      align-items: center;
    }
    .messenger-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
    .link-icon {
      width: 25px;
    }
    p {
      font-size: 18px;
      margin: 0 0 0 20px;
    }
  }
}
