.iiko_resolve_page {
  padding: 0 30px;

  .back_button {
    position: fixed;
    top: 82px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border: none;
    border-radius: 3px;
    outline: none;
    background-color: $blue;
    color: $white;
    font-size: 14px;
    padding: 5px 20px 5px 15px;
    transition: all 0.5s;

    &:hover {
      transform: translateY(-3px);
    }

    .arrow_icon {
      transform: rotate(90deg);
      width: 12px;
      height: auto;
    }
  }

  .iiko_unsolved_header {
    display: flex;
    justify-content: space-between;
  }

  .iiko_tables {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    margin-top: 30px;

    .unsolved_table {
      display: flex;
      flex-direction: column;
      border: 1px solid $gray-700;
      border-radius: 8px;
      width: 50%;
      background-color: $white;
      min-height: 650px;
      max-height: 800px;

      .table_header {
        padding: 20px;
        border-bottom: 1px solid $gray-700;
        width: 100%;

        .table_title {
          color: $black;
          margin: 0;
        }

        .description {
          margin: 0;
          font-size: 12px;
          color: $gray-700;
        }
      }

      .table_body {
        height: 100%;
        overflow: scroll;

        .element {
          border-bottom: 1px solid $gray-700;
          padding: 10px 20px;
          overflow: scroll;
          cursor: pointer;

          .info_section {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .info_row {
              display: flex;
              flex-direction: row;
              gap: 15px;
            }

            .info_text {
              margin: 0;
              font-weight: 700;

              .weight_light {
                font-weight: 400;
              }
            }
          }
        }

        .show_border {
          &:last-of-type {
            border: none !important;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .active {
        background-color: $blue;

        .info_text {
          color: $white;
        }
      }

      .pagination_section {
        margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid $gray-700;
      }
    }
  }
}