.width_500 {
  width: 500px;
}

.capitalize {
  text-transform: capitalize;
}

.resolve_button,
.resolve_button:hover {
  color: white !important;
}

.select_store {
  margin: 30px 0;

  .MuiFormControl-root {
    min-width: 150px;
  }
}

.unresolved_message {
  margin-top: 30px;

  .unresolved_products {
    text-decoration: underline;
  }
}

.iiko_unresolved_message {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  .unresolved_products {
    text-decoration: underline;
  }
}
