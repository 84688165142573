.drag-icon-field {
  margin-right: 10px;
  position: relative;
}

.center-filed {
  margin-left: 20px;
}

.drag-icon-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  cursor: move;
}

.background_unset {
  &::before {
    background: unset !important;
  }
}
