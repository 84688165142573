.iiko_container {
  padding: 0 30px;
  height: 100%;

  .iiko_header {
    display: flex;
    justify-content: space-between;

    .search_filter_field {
      display: flex;
      flex-direction: row;
      gap: 40px;
      justify-content: space-between;
      align-items: center;
    }

    .buttons_filed {
      display: flex;
      flex-direction: row;
      gap: 15px;

      .MuiButton-root.Mui-disabled {
        opacity: 0.7;
        background-color: #3c44b1;
        color: white;
      }
    }
  }

  .filter_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    margin-top: 30px;
    padding: 10px 0;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }


    .element {
      border: 1px solid $gray-700;
      border-radius: 30px;
      padding: 5px 25px 5px 30px;
      font-size: 14px;
      letter-spacing: 1px;
      position: relative;
      color: $gray-900;
      cursor: pointer;
      background: #f4f5fd;
      outline: none;
      white-space: nowrap;

      &::after {
        position: absolute;
        content: "";
        top: 50%;
        right: -25px;
        transform: translateY(-50%);
        width: 25px;
        background-color: $gray-700;
        height: 1px;
      }

      .product_count {
        color: $gray-900;
        font-size: 13px;
        text-align: center;
        display: inline-block;
        margin-left: 5px;
        position: absolute;
        z-index: 10;
        background: white;
        width: fit-content;
        border: 1px solid $gray-700;
        border-radius: 10px;
        padding: 0px 5px;
        top: -30%;
      }

      &:last-child {
        &::after {
          width: 0;
        }
      }
    }

    .active_tab {
      background-color: #4191ff;
      color: white;
    }
  }

  .sync_products {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-700;
    border-radius: 8px;
    position: relative;

    .iiko_header {
      display: flex;
      justify-content: center;
      gap: 15px;
    }

    .sync_warning {
      display: inline-block;
      margin: 20px 0 0;
    }

    .sync_text {
      color: $gray-700;
    }

    .setting_link {
      color: $gray-700;
      text-decoration: underline;

      &:hover {
        color: #7b7b7b;
      }
    }

    .load_icon {
      width: 100%;
      height: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      > svg {
          width: 40px;
          height: 40px;
          animation: anim  0.9s linear  infinite;
      }
    }
  }

  .iiko_tables {
    margin-top: 30px;

    .status_label {
      padding: 6px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      border-radius: 100px;
      font-size: 14px;
      font-weight: 500;
      width: fit-content;
      min-width: 120px;
    }

    .success {
      background-color: $status-success-bg;
      color: $status-success;
    }

    .issue {
      background-color: $status-error-bg;
      color: $status-error;
    }
  }
}