.page_container {
    display: flex;
    width: 100%;
    overflow: hidden;
}
.page_content {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100vh;
}
@media only screen and (max-width: 768px) {
    .page_content {
        margin-left: 0;
        width: 100%;
    }
}
