@import "../../styles/";
.sign-section {
    display: flex;
    justify-content: center;
    > .token_error{
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 4.2rem;
        transform: translate(-50%);
        color: red;
        @include notebook{
            font-size: 35px;
        }
    }
    .load_icon {
        width: 100%;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
            width: 40px;
            height: 40px;
            animation: anim  0.9s linear  infinite;
        }
    }
    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 100px;
        width: 100%;
        max-width: 1240px;
        text-align: center;
        padding: 58px 20px;
        @include phone {
            margin-top: 60px;
            padding: 30px 20px;
        }
        h2 {
            font-weight: 200;
            font-size: 40px;
            line-height: 49px;
            color: #000000;
            margin-bottom: 50px;
            @include phone {
                font-weight: 200;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 20px;
            }
        }
        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            div {
                display: flex;
                flex-direction: row;
                @include phone {
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                }
                > button {
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 17px 37px 17px 30px;
                    background: #ffffff;
                    border: 1px solid #67798950;
                    box-shadow: 0px 0px 20px #00005c18;

                    border-radius: 50px;
                    cursor: pointer;
                    svg {
                        margin-right: 16px;
                    }
                    &:first-child {
                        margin-right: 16px;
                        @include phone {
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                    }
                    @include phone {
                        justify-content: center;
                        width: 100%;
                        max-width: 335px;
                    }
                }
            }
            p,
            h3 {
                margin: 25px 0;
                font-weight: normal;
                font-size: 16px;
                color: #00005c;
                @include phone {
                    margin: 30px 0 20px;
                    font-size: 18px;
                }
            }
            p {
                @include phone {
                    display: none;
                }
            }
            h3 {
                font-weight: bold;
            }
            .sing-by-mail {
                flex-direction: column;
                align-items: center;
                width: 100%;
                .input {
                    border: 1px solid #6779894d;
                }
                button {
                    margin-top: 14px;
                    background: #00005c;
                    color: #ffffff;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    text-transform: uppercase;
                    padding: 20px 68px 20px 62px;
                    width: max-content;
                    transition: 0.3s;
                    &:hover {
                        background-color: #ffffff;
                        color: #00005c;
                        border-color: #00005c;
                    }
                }
            }
        }
        span,
        span > a {
            margin-top: 18px;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
            @include phone {
                margin-top: 20px;
            }
            a {
                text-decoration: underline;
            }
        }
    }
}
@keyframes anim {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
