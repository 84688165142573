@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  src: url('../fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../fonts/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  src: url('../fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Proxima Nova Black';
  font-weight: normal;
  src: url('../fonts/Proxima-Nova-Black.otf');
}

@font-face {
  font-family: 'Proxima Nova Regular';
  font-weight: normal;
  src: url('../fonts/Proxima-Nova-Regular.otf');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-weight: normal;
  src: url('../fonts/Proxima-Nova-Bold.otf');
}

@font-face {
  font-family: 'Proxima Nova Alt Bold';
  font-weight: normal;
  src: url('../fonts/Proxima-Nova-Alt-Bold.otf');
}

@font-face {
  font-family: 'Proxima Nova Extrabold';
  font-weight: normal;
  src: url('../fonts/Proxima-Nova-Extrabold.otf');
}

@font-face {
  font-family: 'Proxima Nova Alt Light';
  font-weight: normal;
  src: url('../fonts/Proxima-Nova-Alt-Light.otf');
}


