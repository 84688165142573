@mixin phone {
  @media (max-width: 767.8px) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: 1023.8px) {
    @content;
  }
}
@mixin notebookMin {
  @media (max-width: 1379.8px) {
    @content;
  }
}
@mixin notebook {
  @media (max-width: 1440px) {
    @content;
  }
}
@mixin tab {
  @media (min-width: 768px) and (max-width: 1023.8px) {
    @content;
  }
}
@mixin not {
  @media (min-width: 1024px) and (max-width: 1379.8px) {
    @content;
  }
}
@mixin ipad-min {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin small {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin elli() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@mixin lineClamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: #{$line};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin container {
  width: 100%;
  max-width: 162.5rem;
  padding: 0 5rem;
  @include notebook {
    max-width: unset;
    padding: 0 20px;
  }
  @include ipad {
    max-width: 1625px;
  }
  @include phone {
    padding: 0 10px;
  }
}

@mixin flex($align, $justify) {
  display: flex;
  align-items: #{$align};
  justify-content: #{$justify};
}
