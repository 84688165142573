@import "../../styles";
.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  > div:first-child {
    width: 50%;
    height: 100vh;
    position: relative;
    z-index: 99999;
    @include ipad {
      display: none;
    }
  }
  .flexSection {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}

