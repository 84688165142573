.support {
  position: fixed;
  top: calc(100dvh - 112px);
  right: 32px;
  z-index: 999;
  @media (max-width: 1379.8px) {
    width: unset !important;
  }
  .support-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 50%;
    background: #1c796b;
    img {
      height: 48px;
      width: 48px;
    }
    .red-point {
      position: absolute;
      right: 6px;
      top: 8px;
      height: 12px;
      width: 12px;
      background: red;
      border-radius: 50%;
    }
  }
  .support-body {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 80px;
  }

  .support-item {
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: none;
    opacity: 0;

    .support-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
  }
  .animated {
    animation-name: opacity;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
